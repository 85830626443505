import { dequal } from "dequal"
import { atom, type WritableAtom, type Getter } from "jotai"
import { atomWithRefresh } from "jotai/utils"

type SELF_UPDATE_ARGS<T> = {
  type: typeof SELF_UPDATE_KEY
  data: Awaited<T>
}

export type SuspendableAtom<
  T,
  Args extends unknown[] | undefined = undefined,
  Result = void,
> = Args extends unknown[]
  ? WritableAtom<T, Args, Result>
  : WritableAtom<T, [], Result>

// create private key so nothing else can update the cache
export const SELF_UPDATE_KEY = Symbol.for("SELF_UPDATE_KEY")

export function withSuspendAtom<T>(
  getter: (get: Getter) => T | Promise<T>
): SuspendableAtom<T>
export function withSuspendAtom<T, Args, Result = void>(
  getter: (get: Getter) => T | Promise<T>,
  writer?: (value: T, args: Args) => T | Promise<T>
): SuspendableAtom<T, [Args], Result>
export function withSuspendAtom<
  T,
  Args extends unknown | undefined = undefined,
  Result = void,
>(
  getter: (get: Getter) => T | Promise<T>,
  writer?: (
    value: T,
    args?: Args extends undefined ? undefined : Args
  ) => T | Promise<T>
): SuspendableAtom<T, Args extends undefined ? [] : [Args], Result> {
  const cacheAtom = atom<null | T>(null)
  let isSelfUpdate = false

  const refreshAtom = atomWithRefresh((get) => {
    return getter(get)
  })
  refreshAtom.debugPrivate = true

  const atomWithCache = atom(
    (get, { setSelf }) => {
      let value = get(refreshAtom)
      const cached = get(cacheAtom)

      if (isSelfUpdate && cached !== null) {
        isSelfUpdate = false
        return cached
      } else {
        if (value instanceof Promise) {
          value = value.then((data) => {
            if (!dequal(data, cached)) {
              setSelf({
                type: SELF_UPDATE_KEY,
                data,
              })
            }

            return data
          })
        }
      }

      if (cached !== null) {
        return cached
      }

      return value
    },
    (get, set, args?: SELF_UPDATE_ARGS<T> | Args) => {
      if ((args as SELF_UPDATE_ARGS<T>)?.type === SELF_UPDATE_KEY) {
        isSelfUpdate = true
        set(cacheAtom, (args as SELF_UPDATE_ARGS<T>).data)
        return
      }

      if (writer) {
        const value = Array.isArray(args)
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            writer(get(cacheAtom) as T, (args as Array<any>)[0])
          : writer(get(cacheAtom) as T)

        if (value instanceof Promise) {
          return value.then((data) => {
            set(cacheAtom, data)
          })
        } else {
          set(cacheAtom, value)
          return
        }
      } else {
        set(cacheAtom, null)
        return set(refreshAtom)
      }
    }
  )

  atomWithCache.debugPrivate = true

  return atomWithCache as unknown as SuspendableAtom<
    T,
    Args extends undefined ? [] : [Args],
    Result
  >
}
