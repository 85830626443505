import { type GETRequests } from "@tokenterminal/tt-analytics-api-types/dist/api-routes"
import { atom, type Atom } from "jotai"
import { atomFamily } from "jotai/utils"
import { type Result } from "../../utils/jotai/unwrap"
import { apiGET } from "../api-get"
import { type Fetcher } from "../create-fetcher"
import { type FetchError } from "../fetch-error"

export type EndpointsGET = Parameters<Fetcher<"get">>[1]

export const fetchApiFamilyAtom = atomFamily((endpoint: EndpointsGET) => {
  const fetchAtom = atom(async () => {
    try {
      const res = await apiGET(endpoint)

      return {
        ok: res,
        err: null,
      }
    } catch (err) {
      return {
        ok: null,
        err: err as FetchError,
      }
    }
  })

  fetchAtom.debugLabel = `fetchApiAtom(${endpoint})`

  return fetchAtom
})

export function fetchApiAtom<T extends EndpointsGET>(
  endpoint: T
): Atom<Promise<Result<GETRequests[T][0], FetchError>>> {
  return fetchApiFamilyAtom(endpoint)
}
