import { type GETRequests } from "@tokenterminal/tt-analytics-api-types/dist/api-routes"

import { dequal } from "dequal"
import { type WritableAtom, atom, type ExtractAtomValue } from "jotai"
import { atomFamily } from "jotai/utils"
import { type Result } from "../../utils/jotai/unwrap"
import { withSuspendAtom } from "../../utils/jotai/withSuspendAtom"
import { type EndpointsGET } from "../api-get"
import { type FetchError } from "../fetch-error"
import { fetchApiAtom, fetchApiFamilyAtom } from "./fetch-api-atom"

const fetchAtomWithRefreshAtom = atomFamily(
  ({ endpoint }: { endpoint: EndpointsGET }) => {
    let baseAtom: ReturnType<typeof fetchApiAtom<EndpointsGET>>

    baseAtom = fetchApiAtom(endpoint as EndpointsGET)
    const suspendableFetchAtom = withSuspendAtom<
      ExtractAtomValue<typeof baseAtom>
    >((get) =>
      // @ts-ignore -- fix types later
      get(baseAtom)
    )

    const fetchAtomWithRefreshAtom = atom(
      (get) => get(suspendableFetchAtom),
      (get, set) => {
        fetchApiFamilyAtom.remove(endpoint as EndpointsGET)
        baseAtom = fetchApiAtom(endpoint as EndpointsGET)

        set(suspendableFetchAtom)
      }
    )
    fetchAtomWithRefreshAtom.debugLabel = `fetchAtomWithRefresh(${endpoint})`

    return fetchAtomWithRefreshAtom
  },
  dequal
)

type ApiResponse<T extends EndpointsGET> = GETRequests[T][0]

type WritableApiAtom<T extends EndpointsGET> = WritableAtom<
  PromiseLike<Result<ApiResponse<T>, FetchError>>,
  [],
  void
>

export function fetchAtomWithRefresh<T extends EndpointsGET>(
  endpoint: T
): WritableApiAtom<T>
export function fetchAtomWithRefresh<T extends EndpointsGET>(
  endpoint: T
): WritableApiAtom<T> {
  return fetchAtomWithRefreshAtom({
    endpoint,
  }) as unknown as WritableApiAtom<T>
}
